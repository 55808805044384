import { post } from "../helpers/request";
import sendTracking from "../helpers/tracking";

export default class ContactForm {
    constructor(options = {}) {
        this.options = Object.assign(
            {},
            {
                optionToShowTextarea: 'autre',
                selectDefaultOptionValue: 'placeholder-value',
                selectors: {
                    form: '.inbenta-km__contact-form form.inbenta-km__contact-form__form',
                    subject: '.inbenta-km__contact-form .inbenta-km__contact-form__form select[name="subject"]',
                    textarea: '.inbenta-km__contact-form .inbenta-km__contact-form__form .inbenta-km__form__input--message',
                    submitButton: '.inbenta-km__form__input--button',
                    loader: '.inbenta-km__contact-form__form__loader',
                    successMsg: '.inbenta-km__contact-form__form__success-msg',
                    errorMsg: '.inbenta-km__contact-form__form__error-msg',
                    requiredElements: '.inbenta-km__contact-form .inbenta-km__contact-form__form .inbenta-km__form__input--required',
                },
            },
            options,
        );
        this.#init();
    }

    #init() {
        // retrieve needed elements
        this.form = this.#retrieveHtmlElement(this.options.selectors.form);
        this.textarea = this.#retrieveHtmlElement(this.options.selectors.textarea);
        this.subjectSelect = this.#retrieveHtmlElement(this.options.selectors.subject);
        this.submitButton = this.#retrieveHtmlElement(this.options.selectors.submitButton);
        this.loader = this.#retrieveHtmlElement(this.options.selectors.loader);
        this.successMsg = this.#retrieveHtmlElement(this.options.selectors.successMsg);
        this.errorMsg = this.#retrieveHtmlElement(this.options.selectors.errorMsg);

        this.trackContactStart = false;

        this.handleStartTrack();
        this.handlePostMessage();
        this.handleFormSubmit();
    }

    handleStartTrack() {
        if (this.form) {
            this.form.addEventListener('change', (event) => {
                if (!this.trackContactStart) {
                    sendTracking('CONTACT_START');
                    this.trackContactStart = true;
                }
            });
        }
    }

    handlePostMessage() {
        if (window.location.host.includes('inbenta.services')) {
            this.messageTargetOrigin = null;
        } else {
            const inbEnv = window.location.host.includes('preprod') ? 'staging' : 'prod';
            this.messageTargetOrigin = 'https://evaneos-b2c-helpsite-' + inbEnv + '.e01.inbenta.services/contact/get-file-iframe';
        }

        const callback = (event) => {
            const data = event?.data;
            if (data === 'fileUploaded') this.submitForm();
            else if (data instanceof Object && 'filePicked' in data) {
                this.filePicked = data.filePicked;
                this.originalFileName = data.originalFileName;
            }
        }
        window.addEventListener('message', callback);
    }

    /**
     * Listen to the form submit to check its required input values and submit the form
     */
    handleFormSubmit() {
        if (this.form) {
            // retrieve required form elements
            this.requiredElements = document.querySelectorAll(this.options.selectors.requiredElements);

            this.form.addEventListener('submit', async (event) => {
                event.preventDefault();

                let isFormValid = true;
                this.formFields = {};

                // iterate each required elements and check their values
                this.requiredElements.forEach((element) => {
                    if (element.classList.contains("inbenta-km__form__input--input")) {
                        const input = element.querySelector("input");

                        // validate the input value
                        const isEmpty = input.value === "";
                        const isEmail = element.classList.contains("inbenta-km__form__input--email");
                        const isValidEmail = new RegExp(/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/).test(input.value);

                        if (isEmpty || (isEmail && !isValidEmail)) {
                            this.#showError(element);
                            isFormValid = false;
                        } else {
                            this.formFields[input.id] = input.value;
                            this.#hideError(element);
                            isFormValid = true;
                        }
                    } else if (element.classList.contains('inbenta-km__form__input--select')) {
                        const select = element.querySelector("select");

                        this.formFields[select.id] = select.value;
                        this.#hideError(element);
                        isFormValid = true;

                    } else if (element.classList.contains('inbenta-km__form__input--textarea')) {
                        const textarea = element.querySelector("textarea");

                        const isEmpty = textarea.value === "";
                        if (isEmpty) {
                            console.log('por aqui')
                            this.#showError(element);
                            isFormValid = false;
                        } else {
                            this.formFields[textarea.id] = textarea.value;
                            this.#hideError(element);
                            isFormValid = true;
                        }
                    }
                });

                if (isFormValid) {
                    const hCaptcha = document.querySelector('.h-captcha > iframe');
                    if (hCaptcha && hCaptcha.getAttribute('data-hcaptcha-response') !== '') {
                        this.formFields['hCaptchaToken'] = hCaptcha.getAttribute('data-hcaptcha-response');
                    } else {
                        return;
                    }

                    this.#hideHtmlElement(this.submitButton);
                    this.#showHtmlElement(this.loader);

                    const folderNumber = document.querySelector('#folder-number');
                    if (folderNumber && folderNumber.value !== '') {
                        this.formFields['folderNumber'] = folderNumber.value;
                    }

                    if (this.filePicked) {
                        await post('/faq/contact/get-file-form', {}).then(async (data) => {
                            if ('formData' in data) {
                                this.formFields['serverFileName'] = data.serverFileName;
                                this.formFields['originalFileName'] = this.originalFileName;
                                document.getElementById('file-iframe').contentWindow.postMessage(JSON.parse(JSON.stringify({
                                    fileForm: data,
                                })), this.messageTargetOrigin);
                            } else {
                                this.#showHtmlElement(this.errorMsg);
                            }
                        });
                    } else {
                        this.submitForm();
                    }
                }
            })
        }
    }

    submitForm() {
        post('/faq/contact/submit-form', this.formFields).then((res) => {
            this.#hideHtmlElement(this.loader);
            if ('status' in res && res.status === 'success') {
                sendTracking('CONTACT_SUBMIT');
                this.#showHtmlElement(this.successMsg);
            } else {
                this.#showHtmlElement(this.errorMsg);
            }
        }).catch((_) => {
            this.#hideHtmlElement(this.loader);
            this.#showHtmlElement(this.errorMsg);
        });
    }

    #retrieveHtmlElement(selector) {
        const element = document.querySelector(selector);
        if (!element) {
            console.warn(`[Contact Form] Could not retrieve selector "${selector}"...`);
            return null;
        }

        return element;
    }

    /**
     * Hide the given html element
     * 
     * @param {HTMLElement|Element} element Any HTML Element
     */
    #hideHtmlElement(element) {
        element.classList.add("inbenta-km-hidden");
    }

    /**
     * Show the given html element
     * 
     * @param {HTMLElement|Element} element Any HTML Element
     */
    #showHtmlElement(element) {
        element.classList.remove("inbenta-km-hidden");
    }

    /**
     * Display error on the given element
     * 
     * @param {HTMLElement|Element} element 
     */
    #showError(element) {
        // add error class on the hole element
        element.classList.add("inbenta-km__form__input--error");

        // remove hidden class on the error input element to show the error message
        const inputError = element.querySelector(".input__error");
        inputError.classList.remove("inbenta-km-hidden");
    }

    /**
     * Hide error on the given element
     * 
     * @param {HTMLElement|Element} element 
     */
    #hideError(element) {
        // remove error class on the hole element
        element.classList.remove("inbenta-km__form__input--error");

        // add hidden class on the error input element to hide the error message
        const inputError = element.querySelector(".input__error");
        inputError.classList.add("inbenta-km-hidden");
    }

    /**
     * Check if the given select selected option is equal to the default one
     * 
     * @param {HTMLSelectElement} select An HTML Select element
     * 
     * @returns {boolean}
     */
    isDefaultSelectOption(select) {
        return select[select.selectedIndex].getAttribute('value').toLowerCase() === this.options.selectDefaultOptionValue.toLowerCase();
    }

    /**
     * Check if the given select selected option is equal to the special one that shows the textarea after
     * 
     * @param {HTMLSelectElement} select An HTML Select element
     * 
     * @returns {boolean}
     */
    isTextareaSelectOption(select) {
        return select[select.selectedIndex].getAttribute('value').toLowerCase() === this.options.optionToShowTextarea.toLowerCase();
    }
}
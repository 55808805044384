/**
 * @author BOUCHER Clément <cboucher@inbenta.com>
 * @copyright Inbenta Technologies Inc.
 */

import { get } from '../helpers/request';
import bindClickEvent from '../helpers/clicks';

/**
 * Autocompleter class used to handle search input
 */
export default class Autocompleter {
  constructor(options = {}) {
    this.options = Object.assign(
      {},
      {
        typingInterval: 200,
      },
      options,
    );

    this.#init();
  }

  #init() {
    let typingTimer;
    const searchFormNode = document.querySelector('.inbenta-km__search__form');
    const searchInputNode = document.querySelector('.inbenta-km__search__form .inbenta-km-input');
    const autocompleteNode = document.querySelector('.inbenta-km__search__form .inbenta-km__autocompleter');
    const formButtonNode = document.querySelector('.inbenta-km__search__form .form__button .inbenta-km-button');

    if (searchInputNode && autocompleteNode && formButtonNode && searchFormNode) {
      // open the autocomplete dropdown when the user focus on search input
      searchInputNode.addEventListener('focus', () => {
        this.showAutocompleteDropdown(autocompleteNode);
      });

      // setTimeout is a hack that prevents CSS transitions to be played at page load.
      setTimeout(() => searchInputNode.focus(), 1);

      // open the autocomplete dropdown when the user clicks on search input
      searchInputNode.addEventListener('click', (event) => {
        this.showAutocompleteDropdown(autocompleteNode);
        event.stopPropagation(); // Stop Propagation 'click'
      });

      // on keyup in the search input, start the countdown
      searchInputNode.addEventListener('input', () => {
        clearTimeout(typingTimer);
        typingTimer = setTimeout(() => {
          var query = searchInputNode.value;
          if (query.trim() !== '') formButtonNode.classList.remove('inbenta-km-button—-disabled');
          this.getAutocompleteResults(query, autocompleteNode)
        }, this.options.typingInterval);
      });

      // on keydown in the search input, clear the countdown
      searchInputNode.addEventListener('keydown', (event) => {
        clearTimeout(typingTimer);
        if (event.target.value !== '') {
          formButtonNode.classList.remove('inbenta-km-button—-disabled');
        }
      });

      // submit search query when clicking on search button
      formButtonNode.addEventListener('click', () => {
        searchFormNode.submit();
      });

      // hide the dropdown menu when the user click outside the autocomplete component
      document.addEventListener('click', () => {
        this.hideAutocompleteDropdown(searchInputNode, autocompleteNode);
      });

      // check when the user press a key to navigate in the autocomplete dropdown (Up,Down,Enter,Escape,Tab)
      document.addEventListener("keydown", (event) => {
        let autocompleteIsFocused = (searchInputNode === document.activeElement);
        // Checking if one of the autocompleter results is focused...
        const autocompleteResultNodes = document.querySelectorAll('.inbenta-km__autocompleter__link');
        autocompleteResultNodes.forEach((autocompleteResultNode) => {
          if (autocompleteResultNode === document.activeElement) {
            autocompleteIsFocused = true;
          }
        })

        if (autocompleteIsFocused) {
          switch (event.which) {
            case 40: // Down key
              event.preventDefault();
              this.focusNextResult();
              break;
            case 38: // Up key
              event.preventDefault();
              this.focusPrevResult(searchInputNode);
              break;
            case 13: // Enter key
              this.submitAutocomplete(event);
              break;
            case 27: // Escape key
              event.preventDefault();
              this.hideAutocompleteDropdown(searchInputNode, autocompleteNode);
              break;
            case 9: // Tab key
              event.preventDefault();
              this.hideAutocompleteDropdown(searchInputNode, autocompleteNode);
              break;
            default: // If user continue to type and a result is focused
              searchInputNode.focus();
          }
        }
      });
    }
  }

  /**
   * Show autocomplete dropdown if it has something to show
   *
   * @param {HTMLElement} autocompleteNode
   */
  showAutocompleteDropdown(autocompleteNode) {
    // Check if there is results in the autocomplete dropdown before display it
    if (document.querySelectorAll('.inbenta-km__search__form .inbenta-km__autocompleter .inbenta-km__autocompleter__link').length > 0) {
      autocompleteNode.classList.remove('inbenta-km-hidden');
    }
  }

  /**
   * Hide autocomplete dropdown
   *
   * @param {HTMLElement} searchInputNode
   * @param {HTMLElement} autocompleteNode
   */
  hideAutocompleteDropdown(searchInputNode, autocompleteNode) {
    searchInputNode.blur();
    autocompleteNode.classList.add('inbenta-km-hidden');
  }

  /**
   * Get autocomplete results and handle response
   *
   * @param {string} query
   * @param {HTMLElement} autocompleteNode
   */
  getAutocompleteResults(query, autocompleteNode) {
    if (query.trim() !== '') {
      get(`/faq/autocomplete?query=${query}`)
        .then((response) => {
          this.formatAutocompleteResponse(response, autocompleteNode);
        });
    }
  }

  /**
   * Format autocomplete API Response
   *
   * @param {Array<{ id: number, seoFriendlyUrl: string, titleHighlight: string, data: Array }>} response
   * @param {HTMLElement} autocompleteNode
   */
  formatAutocompleteResponse(response, autocompleteNode) {
    // Remove all previous results in autocomplete dropdown list
    while (autocompleteNode.firstChild) {
      autocompleteNode.removeChild(autocompleteNode.firstChild);
    }

    response.forEach((content, index) => {
      const autocompleteResult = document.createElement('a');
      autocompleteResult.className = 'inbenta-km__autocompleter__link';
      autocompleteResult.tabindex = index;
      autocompleteResult.href = content.seoFriendlyUrl;
      autocompleteResult.innerHTML = content.titleHighlight;
      autocompleteResult.setAttribute('data-trackdata', JSON.stringify(content.data));
      autocompleteResult.setAttribute('data-tracking', 'a');
      autocompleteNode.appendChild(autocompleteResult);

      autocompleteResult.addEventListener('click', (event) => {
        event.preventDefault();
        bindClickEvent(autocompleteResult);
      });
    })

    // show the autocomplete dropdown if there is results
    if (response.length > 0) {
      this.showAutocompleteDropdown(autocompleteNode);
    }
  }

  /**
   * Return the index of currently focused autocomplete result
   *
   * @param {NodeListOf<Element>|NodeListOf<HTMLElement>} autocompleteResultNodes
   *
   * @return {number}
   */
  getFocusedResult(autocompleteResultNodes) {
    let currentFocusIndex = -1;

    autocompleteResultNodes.forEach((node, index) => {
      if (node === document.activeElement) {
        currentFocusIndex = index;
      }
    });

    return currentFocusIndex;
  }

  /**
   * Focus next autocomplete result
   */
  focusNextResult() {
    const autocompleteResultNodes = document.querySelectorAll('.inbenta-km__autocompleter__link');
    if (autocompleteResultNodes.length > 0) {
      let nextFocusIndex = this.getFocusedResult(autocompleteResultNodes) + 1;
      // Stay on last result if the user continue to tap on Down Key
      nextFocusIndex = Math.min(autocompleteResultNodes.length - 1, nextFocusIndex);
      autocompleteResultNodes[nextFocusIndex].focus();
    }
  }

  /**
   * Focus previous autocomplete result
   *
   * @param {HTMLElement} searchInputNode
   */
  focusPrevResult(searchInputNode) {
    const autocompleteResultNodes = document.querySelectorAll('.inbenta-km__autocompleter__link');
    if (autocompleteResultNodes.length > 0) {
      const previousFocusIndex = this.getFocusedResult(autocompleteResultNodes) - 1;
      // Stay on search input if the user continue to tap on Up Key
      if (previousFocusIndex < 0) {
        searchInputNode.focus();
      } else {
        autocompleteResultNodes[previousFocusIndex].focus();
      }
    }
  }

  /**
   * Submit autocomplete
   *
   * @param {Event} event
   */
  submitAutocomplete(event) {
    const autocompleteResultNodes = document.querySelectorAll('.inbenta-km__autocompleter__link');
    if (autocompleteResultNodes.length > 0) {
      const currentFocusIndex = this.getFocusedResult(autocompleteResultNodes);
      // Forcing navigation to the focused result page if applicable...
      if (currentFocusIndex >= 0) {
        event.preventDefault();

        // setup click event to register autocompleter click + redirect to the content url
        bindClickEvent(autocompleteResultNodes[currentFocusIndex]);
      }
    }
  }

}
